import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

const getInitialStateCreateReservation = localStorage.getItem('createReservation') ?
    JSON.parse(localStorage.getItem('createReservation')) : 0;


const initialStateCreateReservation = getInitialStateCreateReservation === 0 ? 
{
    value:0
}  : {
    reservation_created:getInitialStateCreateReservation,
}

export const reservationBlockedDaysSlice = createSlice({
    name: 'reservationBlockedDays',
    initialState,
    reducers: {
        reservationBlockedDaysRequest: (state) => {
            state.isLoading = true;
        },
        reservationBlockedDaysSucess: (state, action) => {
            state.blockedDays = action.payload;
            state.isLoading = false;
        },
        reservationBlockedDaysError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
})

export const reservationBlockedWeekdaysSlice = createSlice({
    name: 'reservationBlockedWeekdays',
    initialState,
    reducers: {
        reservationBlockedWeekdaysRequest: (state) => {
            state.isLoading = true;
        },
        reservationBlockedWeekdaysSucess: (state, action) => {
            state.blockedWeekdays = action.payload;
            state.isLoading = false;
        },
        reservationBlockedWeekdaysError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        // Checking if restaurant is still open in the correct time zone 
        isRestaurantOpenTodayRequest: (state) => {
            state.loadingIsRestaurantOpen = true;
        },
        isRestaurantOpenTodaySucess: (state, action) => {
            state.isRestaurantOpen = action.payload;
            state.loadingIsRestaurantOpen = false;
        },
        isRestaurantOpenTodayError: (state, action) => {
            state.errorIsRestaurantOpen = action.payload;
            state.loadingIsRestaurantOpen = false;
        },
        // Checking if restaurant is still open in the correct time zone 
        restaurantTimeZoneRequest: (state) => {
            state.loadingRestaurantTimeZone = true;
        },
        restaurantTimeZoneSucess: (state, action) => {
            state.restaurantTimeZone = action.payload;
            state.loadingRestaurantTimeZone = false;
        },
        restaurantTimeZoneError: (state, action) => {
            state.errorIsRestaurantTimeZone = action.payload;
            state.loadingRestaurantTimeZone = false;
        },
    },
})

export const reservationShiftSlice = createSlice({
name: 'reservationShift',
    initialState,
    reducers: {
        reservationShiftRequest: (state) => {
            state.isLoading = true;
        },
        reservationShiftSucess: (state, action) => {
            state.shift = action.payload;
            state.isLoading = false;
        },
        reservationShiftError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
})
  
export const openTimeSlice = createSlice({
    name: 'openTime',
    initialState,
    reducers: {
        openTimeRequest: (state) => {
            state.isLoading = true;
            state.openTime = [];
        },
        openTimeSucess: (state, action) => {
            state.openTime = action.payload;
            state.isLoading = false;
        },
        openTimeError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
})

export const createReservationSlice = createSlice({
    name: 'createReservation',
    initialState: initialStateCreateReservation,
    reducers: {
        createReservationRequest: (state) => {
            state.isLoading = true;
        },
        createReservationSucess: (state, action) => {
            state.reservation_created = action.payload;
            state.isLoading = false;
            state.error = false;
            state.createReservationFailed = false;
        },
        createReservationError: (state, action) => {
            state.error = action.payload;
            state.createReservationFailed = true;
            state.isLoading = false;
        },
        // postPhoneConfirmation 
        postPhoneConfirmationStart: (state) => {
            state.isPhoneConfirmationLoading = true;
        },
        postPhoneConfirmationSucess: (state, action) => {
            state.phoneConfirmation = action.payload;
            state.isPhoneConfirmationLoading = false;
        },
        postPhoneConfirmationError: (state, action) => {
            state.phoneConfirmationError = action.payload;
            state.isPhoneConfirmationLoading = false;
        },
        // postResendPhoneConfirmation 
        postResendPhoneConfirmationStart: (state) => {
            state.isResendPhoneConfirmationLoading = true;
        },
        postResendPhoneConfirmationSucess: (state, action) => {
            state.resendPhoneConfirmation = action.payload;
            state.isResendPhoneConfirmationLoading = false;
        },
        postResendPhoneConfirmationError: (state, action) => {
            state.resendPhoneConfirmationError = action.payload;
            state.isResendPhoneConfirmationLoading = false;
        },
        // postCheckCancel 
        postCheckCancelStart: (state) => {
            state.checkCancel = false;
            state.isCheckCancelLoading = true;
        },
        postCheckCancelSucess: (state, action) => {
            state.checkCancel = action.payload;
            state.isCheckCancelLoading = false;
        },
        postCheckCancelError: (state, action) => {
            state.checkCancelnError = action.payload;
            state.isCheckCancelLoading = false;
        },
        
        // reset
        resetReservationError: () => {
            return {}
        },
    },
})

export const cancelReservationSlice = createSlice({
    name: 'cancelReservation',
    initialState,
    reducers: {
        cancelReservationRequest: (state) => {
            state.isLoadingCancel = true;
        },
        cancelReservationSucess: (state, action) => {
            state.cancelMessage= action.payload;
            state.isLoadingCancel = false;
        },
        cancelReservationError: (state, action) => {
            state.error = action.payload;
            state.isLoadingCancel = false;
        },
    },
})

export const editReservationSlice = createSlice({
    name: 'editReservation',
    initialState,
    reducers: {
        editReservationRequest: (state) => {
            state.isLoadingEditReservation = true;
        },
        editReservationSucess: (state, action) => {
            state.editReservation = action.payload;
            state.isLoadingEditReservation = false;
        },
        editReservationError: (state, action) => {
            state.errorEditReservations = action.payload;
            state.isLoadingEditReservation = false;
        },
    },
})
  
export const reservationCustomersMenuSlice = createSlice({
    name: 'cancelReservation',
    initialState,
    reducers: {
        getReservationCustomerRequest: (state) => {
            state.isLoading = true;
        },
        getReservationCustomerSucess: (state, action) => {
            state.reservation = action.payload;
            state.isLoading = false;
        },
        getReservationCustomerError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        // Special Loader for customer menu so it doesnt load the loader component wen the page it's autoupdating itself
        startCustomerMenuLoader: (state, action) => {
            state.isCustomerLoader = true;
        },
        stopCustomerMenuLoader: (state, action) => {
            state.isCustomerLoader = false;
        },
        resetReservationCustomerMenu: () => {
            return {}
        },
    },
})

export const reservationLimitPeopleBabySlice = createSlice({
    name: 'reservationLimitPeopleBaby',
    initialState,
    reducers: {
        getPeopleBabyRequest: (state) => {
            state.isLoading = true;
        },
        getLimitsPeopleBabySucess: (state, action) => {
            state.limitPeopleBaby = action.payload;
            state.isLoading = false;
        },
        getPeopleBabyError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
})

export const reservationActiveSlice = createSlice({
    name: 'reservationActive',
    initialState,
    reducers: {
        getReservationActiveRequest: (state) => {
            state.isActive = true;
        },
        getReservationActiveError: (state, action) => {
            state.error = action.payload;
        },
        getReservationDeactivateRequest: (state, action) => {
            state.isActive = false;
        },
    },
})
  
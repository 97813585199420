import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlus, faCalendar, 
        faFilter, faSun, faMap, faCircleDot, 
        faStopwatch, faChair, faUsers, faCircleXmark,
        faFilePen, faRectangleXmark, faEnvelope, 
        faWheelchairMove, faBaby, faBell, faReply,
        faSearch, faSquareCheck, faEye, faPhone, faCircleCheck, faGear,
         } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Card, Modal, Button, ModalBody } from 'react-bootstrap';
import styled from 'styled-components';
import { postReservationAccept,  
         updateCustomerAttendance,
         changeTableNumber } from '../actions/restaurantActions';
import { fetchCancelReservation, checkCancelAction  } from '../actions/reservationsActions';
import moment from 'moment-timezone';
import CompareChanges from './CompareChanges';

const StyledReservationStatusBox = styled.div`
  display: flex;
  flex-wrap:wrap;
  width:200px;
  height:120px;
  border:1px solid gray;
  border-radius:5px;
  margin-right:5px;
  margin-bottom:5px;
  position:absolute;
  bottom:0;
  right:0;
  overflow:hidden;
`;

const TopBox = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  width:100%;
  height:28px;
  border:1px solid gray;
  background-color: ${props => (props.status === 'Cancelled' || 
                      props.status === 'Cancelled_By_Customer' ||
                      props.status === 'Cancelled_By_Restaurant' ||
                      props.status === 'Did_Not_Attend' ? '#e8733c'
                      : props.status === 'Cancelled_By_Expired_Code' ? '#dcb1e0'
                      : props.status === 'Pending_from_customer_Warning' ? '#d6a7c1'
                      : props.status === 'Edited' ? '#7e538a'
                      : props.status === 'Pending_from_restaurant' ? '#cacf44'
                      : props.status === 'Pending_from_customer' ? '#959657'
                      : props.status === 'Late' ? '#ebb052'
                      : '#58c49f')};
  color:white;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height:40px;
  border-top:1px solid gray;
  left:0;
  bottom:0;
  position:absolute;
`;

const LeftButton = styled.div`
  font-size:12px;
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  width:50%;
  height:100%;
  border:1px solid gray;
  background-color:#58c49f;
  color:white;
  left:0;
  bottom:0;
  position:absolute;
  z-index:1;
  cursor:pointer;
  &:hover {
    background-color: #81e3bf;
  }
`;

const RightButton = styled.div`
  font-size:12px;
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  width:50%;
  height:100%;
  border:1px solid gray;
  background-color:#e8733c;
  color:white;
  bottom:0;
  right:0;
  position:absolute;
  z-index:1;
  cursor:pointer;
  &:hover {
    background-color: #e6915c;
  }
`;

const FullButton = styled.div`
  font-size:12px;
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  width:100%;
  height:40px;
  border:1px solid gray;
  background-color:${props => (props.status === 'Cancelled' || 
                              props.status === 'Cancelled_By_Customer' ||
                              props.status === 'Cancelled_By_Restaurant' ||
                              props.status === 'Cancelled_By_Expired_Code' ||
                              props.status === 'Did_Not_Attend' ? '#e8733c' 
                              : props.status === 'Pending_from_restaurant' ? '#cacf44'
                              : props.status === 'Late' ? '#ebb052'
                              : '#58c49f')};
  color:white;
  bottom:0;
  right:0;
  position:absolute;
  z-index:1;
  cursor:pointer;
  &:hover {
    background-color: ${props => (props.status == 'Cancelled' || 
                                  props.status == 'Cancelled_By_Customer' ||
                                  props.status == 'Cancelled_By_Restaurant' ||
                                  props.status == 'Cancelled_By_Expired_Code' ||
                                  props.status == 'Did_Not_Attend' 
                                  ? '#e8733c' 
                                  : '#81e3bf')};
  }
`;

const Circle = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  width:60px;
  height:60px;
  border:1px solid gray;
  border-radius:50%;
  background-color:white;
  color:black;
  bottom:50%;
  right:50%;
  position:absolute;
  transform: translate(50%, 47%);
  cursor:pointer;
  z-index:4;

  &:hover {
    background-color: #ededed;
  }
`;

const NumberBoxes = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width:35px;
  height:35px;
  border:1px solid gray;
  border-radius:4px;
  background-color:white;
  color:black;
  margin:5px;
  cursor:pointer;
  &:hover {
    background-color: #ededed;
  }
`;

function ReservationStatusBox({reservation, updateSearchResultsTableNumber, index, updateReservations, statusBoxRef=null, reservations, fetchAllReservations}) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [numbers, setNumbers] = useState(Array.from({ length: 101 }, (_, index) => index));
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [showAttendanceOptions, setShowAttendanceOptions] = useState(false);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [showNotAttendanceModal, setShowNotAttendanceModal] = useState(false);
  const [showModalRestoreReservation, setShowModalRestoreReservation] = useState(false);
  const [rejectEdition, setRejectEdition] = useState(false);
  const [reservationBeforeEdition, setReservationBeforeEdition] = useState(null);

  useEffect(() => {
    // Find the specific history item where history_id matches restore_version_id
    const filteredHistoryItem = reservation.history.find(item => item.history_id === reservation.restore_version_id);
    // Set the reservation before edition with the filtered history item
    setReservationBeforeEdition(filteredHistoryItem); 
  }, [reservation]);
  
  const restaurantTimeZone = useSelector((state) => state.reservationBlockedWeekDays.restaurantTimeZone);

  useEffect(() => {
    if (restaurantTimeZone) {
      const currentTime = moment.tz(restaurantTimeZone.timezone);
      const currentTimeToShowAttendance = currentTime.clone().add(30, 'minutes');
      const currentTimeAfter = currentTime.clone().subtract(15, 'minutes');
      setCurrentDateTime(currentTimeAfter);
      const reservationDateTime = moment.tz(
        `${reservation.date} ${reservation.time}`,
        'YYYY-MM-DD HH:mm',
        restaurantTimeZone.timezone
      );
      if (reservationDateTime.isBefore(currentTimeAfter) && 
          (reservation.status === 'Accepted' || 
          reservation.status === 'Accepted_With_Table' || 
          reservation.status === 'Accepted_Without_Table')) {
        updateSearchResultsTableNumber(index, reservation.table_number, 'Late');
      }
      if (reservationDateTime.isBefore(currentTimeToShowAttendance)) {
        setShowAttendanceOptions(true);
      }
      
    }
  }, [restaurantTimeZone, reservation, updateSearchResultsTableNumber, index]);


  const handleCloseModal = () => {
      setShowModal(false);
    };

  const handleChangeTable = (number, isNotAllowed) => {
    if (!isNotAllowed) {
      let status;
      if (number !== null && reservation.status === 'Accepted_Without_Table') {
        status = 'Accepted_With_Table'
      } else if (reservation.status === 'Accepted_With_Table') {
        status = 'Accepted_Without_Table'
      } else {
        status = reservation.status
      }
      dispatch(changeTableNumber(reservation.token, number))
      updateSearchResultsTableNumber(index, number, status)
      setShowModal(false);
    };
    
  };

  const handleShowAcceptModal = (str) => {
    if (str === 'rejectEdition') {
      setRejectEdition(true);
    } else {
      setRejectEdition(false);
    }
    setShowAcceptModal(true);
  };

  const handleCloseAcceptModal = () => {
    setShowAcceptModal(false);
  };

  const handleShowCancelModal = (str) => {
    if (str === 'rejectEdition') {
      setRejectEdition(true);
    } else {
      setRejectEdition(false);
    }
    setShowCancelModal(true);
  };
  
  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleAccept = () => {
    let status;
    if (reservation.table_number !== '' || reservation.table_number !== 'null' || reservation.table_number !== null) {
      status = 'Accepted_With_Table'
    } else {
      status = 'Accepted_Without_Table'
    }
    if (rejectEdition) {
      dispatch(postReservationAccept(reservation.token, false, false, false, false, false, false, true));
    } else {
      dispatch(postReservationAccept(reservation.token, false, false, false, false, false, false, false));
    }
    updateSearchResultsTableNumber(index, reservation.table_number, status)
    setShowAcceptModal(false);
  };

  const handleCancel = () => {
    let status = 'Cancelled_By_Restaurant';
    updateSearchResultsTableNumber(index, reservation.table_number, status)
    
    setShowCancelModal(false);
    if (rejectEdition) {
      dispatch(fetchCancelReservation(reservation.token, false, false, false, false, false, true, true));
    } else {
      dispatch(fetchCancelReservation(reservation.token, false, false, false, false, false, false, false));
    }
  };

  const handleCheckCancel = (token) => {
    dispatch(checkCancelAction(token));
  };

  const handleAttended = (token) => {
    dispatch(updateCustomerAttendance(reservation.token, 'attended'));
    updateSearchResultsTableNumber(index, reservation.table_number, 'Attended')
    setShowAttendanceModal(false);
  };

  const handleNotAttended = token => {
    dispatch(updateCustomerAttendance(reservation.token, 'not_attended'));
    updateSearchResultsTableNumber(index, reservation.table_number, 'Did_Not_Attend')
    setShowNotAttendanceModal(false);
  };

  const handleShowAttendanceModal = () => {
    setShowAttendanceModal(true);
  };

  const handleHideAttendanceModal = () => {
    setShowAttendanceModal(false);
  };

  const handleShowDidNotAttendanceModal = () => {
    setShowNotAttendanceModal(true);
  };
  
  const handleHideDidNotAttendanceModal = () => {
    setShowNotAttendanceModal(false);
  };

  const handleShowModalRestoreReservation = () => {
    setShowModalRestoreReservation(true);
  };

  const handleHideModalRestoreReservation = () => {
    setShowModalRestoreReservation(false);
  };
 
  const handleRestoreCancel = token => {
    let status;
    
    if (reservation.table_number !== '' || reservation.table_number !== 'null' || reservation.table_number !== null) {
      status = 'Accepted_With_Table'
    } else {
      status = 'Accepted_Without_Table'
    }
    dispatch(updateCustomerAttendance(reservation.token, 'waiting'));
    updateSearchResultsTableNumber(index, reservation.table_number, status)
    setShowModalRestoreReservation(false);
  };

  // Step 1: Define a function to check time overlap
const checkOverlap = (newStartTime, newEndTime, existingStartTime, existingEndTime) => {
  return newStartTime < existingEndTime && newEndTime > existingStartTime;
};

const markNotAllowedTables = () => {
  if (reservations) {
    // Assuming you're using today's date for the base date
    const baseDate = new Date().toISOString().substring(0, 10); // "YYYY-MM-DD"

    const existingStartTime = new Date(`${baseDate}T${reservation.time}:00`); // Adds the base date to the time
    const existingEndTime = new Date(`${baseDate}T${reservation.estimatedtime}:00`); // Adds the base date to the estimated time

    // console.log('existingStartTime: ', existingStartTime, 'existingEndTime: ', existingEndTime);

    const markedNumbers = numbers.map(number => {
      const isNotAllowed = reservations.some(reservation => {
        if (reservation.table_number === number.toString()) {
          const newStartTime = new Date(`${baseDate}T${reservation.time}:00`); // Adjust based on your logic
          const newEndTime = new Date(`${baseDate}T${reservation.estimatedtime}:00`); // Adjust based on your logic

          return checkOverlap(newStartTime, newEndTime, existingStartTime, existingEndTime);
        }
        return false;
      });

      return { number, isNotAllowed };
    });

    return markedNumbers;
  }
};

// Call filterTableNumbers when you need to, such as on component mount or when reservations change
useEffect(() => {
  if (reservations) {
    markNotAllowedTables();
  }
}, [reservations, reservation]);

  return (
    <div >
        <StyledReservationStatusBox>
            <TopBox status={reservation.status}>
                {reservation.status === 'Pending_from_customer' ? 'Reserva em curso' :
                 reservation.status === 'Pending_from_restaurant' ? 'Pendente de aceitação' :
                 reservation.status === 'Edited' ? 'Pedido de alteração' :
                 reservation.status === 'Late' ? 'Atrazo!' :
                 reservation.status === 'Waiting_List' ? 'Lista de espera' :
                 reservation.status === 'Accepted' || 
                 reservation.status === 'Accepted_Without_Table' || 
                 reservation.status === 'Accepted_With_Table' ? 'Reserva Aceite' :
                 reservation.status === 'Attended' ? 'Reserva concluida' :
                 reservation.status === 'Did_Not_Attend' ? 'Não compareceu!' :
                 reservation.status === 'Cancelled_By_Customer' || reservation.status === 'Cancelled' ? 'cliente cancelou' :
                 reservation.status === 'Cancelled_By_Restaurant' ? 'restaurante cancelou' :
                 reservation.status === 'Cancelled_By_Expired_Code' ? 'código expirado' :
                 'Null'}
            </TopBox>
            {reservation.status === 'Accepted_With_Table' ||
             reservation.status === 'Accepted_Without_Table' ||
             reservation.status === 'Attended' ||
             reservation.status === 'Accepted' ||
             reservation.status === 'Late' ||
             reservation.status === 'Did_Not_Attend' ?
              <Circle onClick={() => setShowModal(!showModal)}>
                {reservation.table_number === null || reservation.table_number === 'null' ? '' : reservation.table_number} 
              </Circle>
              :
              <></>
            }

            {(reservation.status === 'Pending_from_customer'  || 
              reservation.status === 'Pending_from_restaurant') &&
              <ButtonsWrapper>
                <LeftButton onClick={() => handleShowAcceptModal()}>
                    Aceitar
                </LeftButton>
                <RightButton onClick={() => handleShowCancelModal()}>
                    Rejeitar
                </RightButton>
              </ButtonsWrapper>
            }
            {(reservation.status === 'Edited' ) &&
              <ButtonsWrapper>
                <LeftButton onClick={() => handleShowAcceptModal('rejectEdition')}>
                    Aceitar
                </LeftButton>
                <RightButton onClick={() => handleShowCancelModal('rejectEdition')}>
                    Rejeitar
                </RightButton>
              </ButtonsWrapper>
            }

            {((reservation.status === 'Accepted'  ||  
              reservation.status === 'Accepted_With_Table' ||
              reservation.status === 'Late' ||
              reservation.status === 'Accepted_Without_Table') && showAttendanceOptions) ?
                  <ButtonsWrapper>
                    <LeftButton onClick={handleShowAttendanceModal}>
                        Compareceu
                    </LeftButton>
                    <RightButton onClick={handleShowDidNotAttendanceModal}>
                        Não Compareceu
                    </RightButton>
                  </ButtonsWrapper>
                : reservation.status !== 'Pending_from_customer'  || 
                reservation.status !== 'Pending_from_restaurant'  ||  
                reservation.status !== 'Edited'?
                <ButtonsWrapper/>
                :
                <></>
            }

            {reservation.cancelled_warning === true
             ?
              <FullButton onClick={() => handleCheckCancel(reservation.token)}>
                <FontAwesomeIcon size='xl' icon={faEye} style={{color: "#ffffff", marginLeft:'10px'}} />
              </FullButton>
              : reservation.status === 'Cancelled_By_Expired_Code' ?
              <>
                <LeftButton  style={{height:'40px'}} onClick={() => handleCheckCancel(reservation.token)}>
                  <FontAwesomeIcon size='xl' icon={faEye} style={{color: "#ffffff", marginLeft:'10px'}} />
                </LeftButton>
                <RightButton style={{height:'40px'}} onClick={handleShowModalRestoreReservation}>
                  <FontAwesomeIcon size='xl'  icon={faReply} style={{color: "#ffffff", marginLeft:'10px'}} />
                </RightButton>
              </>
              : reservation.status === 'Attended' ||
                reservation.status === 'Did_Not_Attend' ||
                reservation.status === 'Cancelled' ||
                reservation.status === 'Cancelled_By_Customer' ||
                reservation.status === 'Cancelled_By_Restaurant'  ?
                <FullButton status={reservation.status} onClick={handleShowModalRestoreReservation}>
                  <FontAwesomeIcon size='xl'  icon={faReply} style={{color: "#ffffff", marginLeft:'10px'}} />
                </FullButton>
                :
                <>
                </>
            }
        </StyledReservationStatusBox>

        {/* Choose tables modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <div ref={statusBoxRef}>
            <Modal.Header closeButton>
                <Modal.Title>Escolher Numero</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight:'200px', overflow:'auto', display:'flex', flexWrap:'wrap'}}>
            <NumberBoxes onClick={() => handleChangeTable(null)}></NumberBoxes>
            {reservations && markNotAllowedTables().map(({ number, isNotAllowed }) => {
                return (
                  <NumberBoxes
                    key={number}
                    onClick={() => handleChangeTable(number, isNotAllowed)}
                    style={{ backgroundColor: isNotAllowed ? '#deb949' : 'initial' }} // Change 'red' to any color you prefer for not allowed tables
                  >
                    {number}
                  </NumberBoxes>
                );
              })}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
            </div>
        </Modal>

        <Modal show={showAcceptModal} onHide={handleCloseAcceptModal}>
          <div ref={statusBoxRef}>
          <Modal.Header closeButton>
              <Modal.Title>{rejectEdition ? 'Aceitar alteração' : 'Aceitar reserva?'}</Modal.Title>
            </Modal.Header>
            {(rejectEdition && reservation && reservationBeforeEdition) &&
              <Modal.Body>
                <CompareChanges 
                before={reservationBeforeEdition} 
                after={reservation} 
                />
              </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="success" onClick={handleAccept}>
                  Sim
                </Button>
                <Button variant="danger" onClick={handleCloseAcceptModal}>
                  Não
                </Button>
            </Modal.Footer>
          </div>
            
          </Modal>

          <Modal show={showCancelModal} onHide={handleCloseCancelModal}>
          <div ref={statusBoxRef}>
            <Modal.Header closeButton>
              <Modal.Title>{rejectEdition ? 'Rejeitar alteração?' : 'Rejeitar reserva?'}</Modal.Title>
            </Modal.Header>
            {(rejectEdition && reservation && reservationBeforeEdition) &&
              <Modal.Body>
                <CompareChanges 
                before={reservationBeforeEdition} 
                after={reservation} 
                />
              </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="success" onClick={handleCancel}>
                  Sim
                </Button>
                <Button variant="danger" onClick={handleCloseCancelModal}>
                  Não
                </Button>
            </Modal.Footer>
            </div>
          </Modal>

          <Modal show={showAttendanceModal} onHide={handleHideAttendanceModal}>
          <div ref={statusBoxRef}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmar comparência?</Modal.Title>
            </Modal.Header>
            
            <Modal.Footer>
                <Button variant="success" onClick={() => handleAttended()}>
                  Confirmar
                </Button>
          </Modal.Footer>
            </div>
          </Modal>

          <Modal show={showNotAttendanceModal} onHide={handleHideDidNotAttendanceModal}>
          <div ref={statusBoxRef}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmar não comparência?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="success" onClick={() => handleNotAttended()}>
                  Confirmar
                </Button>
            </Modal.Footer>
            </div>
          </Modal>

          <Modal show={showModalRestoreReservation} onHide={handleHideModalRestoreReservation}>
          <div ref={statusBoxRef}>
            <Modal.Header closeButton>
              <Modal.Title>Restaurar reserva?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="success" onClick={handleRestoreCancel}>
                  Sim
                </Button>
                <Button variant="danger" onClick={() => setShowModalRestoreReservation(!showModalRestoreReservation)}>
                  Não
                </Button>
            </Modal.Footer>
            </div>
          </Modal>
    </div>
  )
}

export default ReservationStatusBox
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logout } from '../actions/userActions';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { getRestaurantSubscription } from '../actions/userActions';
import { getManager } from '../actions/managersActions'

const StyledPerfilButton = styled(Button)`
  background-color: #32cd32;
  border: 2px solid #32cd32;
  font-size: 1rem;
  margin: 3px;
  &:hover {
    background-color: #00b300;
    border-color: #00b300;
    cursor: pointer;
  }
`;
const StyledDangerButton = styled(Button)`
  background-color: #ff751a;
  border: 2px solid #ff751a;  
  font-size: 1rem;
  color: white;

  &:hover {
    background-color: #e64a00;
    border-color: #e64a00;
    cursor: pointer;
  }
`;



function Header() {
  const dispatch = useDispatch();

  const [showHeader, setShowHeader] = useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const manager_info = useSelector(state => state.crudManager)
  const { managerInfo } = manager_info

  const restaurant_sub = useSelector((state) => state.restaurant_subscription);
  const { restaurantSubscription } = restaurant_sub;

  useEffect(() => {
    if (restaurantSubscription && restaurantSubscription[0].account_type === 'not_vip') {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [restaurantSubscription]);

  useEffect(() => {
    dispatch(getRestaurantSubscription());
    dispatch(getManager());
  }, [dispatch]);



  const logoutHandler = () => {
    dispatch(logout());
    setShowHeader(true);
  };

  return (
    <header>
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" >
        <Container>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Navbar.Brand style={{ height: '50px' }}><div className='header_title'>Soup<div style={{fontSize: '18px'}}>&nbsp;®</div></div></Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Row className="py-3">
                {userInfo && showHeader? (
                  <Col>
                    <Col sm={12}>
                    <Link to={`/profile/update/${'no'}`} style={{ textDecoration: 'none' }}>
                        <StyledPerfilButton className="mx-2">
                          <i className="fas fa-user"></i>&nbsp;Conta
                        </StyledPerfilButton>
                      </Link>
                      {!managerInfo &&
                        <>
                          <Link to={`/FiscalAddress`} style={{ textDecoration: 'none' }}>
                            <StyledPerfilButton className="mx-2">
                            <i class="fa fa-address-book" aria-hidden="true"></i>&nbsp;Endereços Fiscais
                            </StyledPerfilButton>
                          </Link>
                          <Link to={`/manager_account_creation`} style={{ textDecoration: 'none' }}>
                            <StyledPerfilButton className="mx-2">
                            <i class="fa fa-id-card" aria-hidden="true"></i>&nbsp;Gerir contas
                            </StyledPerfilButton>
                          </Link>
                        </>
                      }
                      <Link to={`/`} style={{ textDecoration: 'none' }}>
                        <StyledPerfilButton className="mx-2">
                        <FontAwesomeIcon icon={faShareFromSquare} style={{color: "#ffffff",}} />&nbsp;Restaurantes
                        </StyledPerfilButton>
                      </Link>
                      <Link to={`/login/${'null'}/${'null'}`} style={{ textDecoration: 'none' }}>
                        <StyledDangerButton
                          onClick={logoutHandler}
                          variant="outline-danger"
                          className="mx-2"
                        >
                          <i className="fas fa-sign-out"></i>&nbsp;Sair da conta
                        </StyledDangerButton>
                      </Link>
                    </Col>
                  </Col>
                ) : ! showHeader ? (
                    <Col sm={12}>
                    <Link to={`/login/${'null'}/${'null'}`} style={{ textDecoration: 'none' }}>
                              <StyledDangerButton
                                onClick={logoutHandler}
                                variant="outline-danger"
                                className="mx-2"
                              >
                                <i className="fas fa-sign-out"></i>&nbsp;Sair da conta
                              </StyledDangerButton>
                            </Link>
                    </Col>
                )
              :
                <Col sm={12}>
                  <Link to={`/login/${'null'}/${'null'}`} style={{ textDecoration: 'none' }}>
                    <Button variant="info" className="mx-2" style={{border: 'solid 1px #008a81'}}>
                      <i className="fas fa-user"></i> Entrar ou criar conta
                    </Button>
                  </Link>
                </Col>
              }
              </Row>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;